<template>
  <div>
    <a-row>
      <a-col :span="8">
        <a-descriptions
          title="基本信息"
          :column="1"
          class="simiot-descriptions"
        >
          <a-descriptions-item label="账户名称">
            {{ carrierAccount.name }}
          </a-descriptions-item>

          <a-descriptions-item label="运营商种类">
            {{ carrierAccount.carrier_type }}
          </a-descriptions-item>

          <a-descriptions-item label="所属省市">
            {{ carrierAccount.region }}
          </a-descriptions-item>

          <a-descriptions-item label="联系人">
            {{ carrierAccount.contact }}
          </a-descriptions-item>

          <a-descriptions-item label="联系邮箱">
            {{ carrierAccount.email }}
          </a-descriptions-item>

          <a-descriptions-item label="联系电话">
            {{ carrierAccount.phone_number }}
          </a-descriptions-item>

          <a-descriptions-item label="签约主体">
            {{ carrierAccount.contracting_party }}
          </a-descriptions-item>

          <a-descriptions-item label="短信功能">
            {{ carrierAccount.is_has_sms_channel }}
          </a-descriptions-item>

          <a-descriptions-item label="短信接口" v-if="carrierAccount.is_has_sms_channel === '开启'">
            {{ carrierAccount.sms_channel }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="登录信息"
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-7"
        >
          <a-descriptions-item label="运营商平台URL">
            <a :href="carrierAccount.carrier_url" target="_blank">
              {{ carrierAccount.carrier_url }}
            </a>

          </a-descriptions-item>

          <a-descriptions-item label="运营商平台账户">
            {{ carrierAccount.carrier_account }}
          </a-descriptions-item>

          <a-descriptions-item label="运营商平台密码">
            {{ carrierAccount.carrier_password }}
          </a-descriptions-item>
        </a-descriptions>

        <a-descriptions
          title="其他信息"
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-9"
        >
          <a-descriptions-item label="计费周期">
            {{ carrierAccount.charge_day }}
          </a-descriptions-item>

          <a-descriptions-item label="普通卡停机保号时长">
            {{ carrierAccount.ordinary_stopped_months }}
          </a-descriptions-item>

          <a-descriptions-item label="NB卡停机保号时长">
            {{ carrierAccount.nb_stopped_months }}
          </a-descriptions-item>

          <a-descriptions-item label="提请销卡日期">
            {{ carrierAccount.request_cancel_card_day }}
          </a-descriptions-item>

          <a-descriptions-item label="备注">
            {{ carrierAccount.remark }}
          </a-descriptions-item>
        </a-descriptions>

      </a-col>

      <a-col :span="8">
        <ShowChinaMobileApiAccount :api-account="apiAccount" v-if="carrierAccount.carrier_type_slug == 'china_mobile'" />
        <ShowChinaUnicomApiAccount :api-account="apiAccount" v-else-if="carrierAccount.carrier_type_slug == 'china_unicom'" />
        <ShowChinaTelecomApiAccount :api-account="apiAccount" v-else-if="carrierAccount.carrier_type_slug == 'china_telecom'" />
      </a-col>

      <a-col :span="8">
        <a-descriptions
          title="操作历史"
        />
        <operation-history-list
          :reference-id="this.carrierAccountId"
          reference-type="CarrierAccount"
          height="600px"
        />
      </a-col>
    </a-row>

  </div>
</template>

<script>
import { findCarrierAccount } from '@/api/carrier_account'
import OperationHistoryList from '@/views/operation_histories'

export default {
  name: 'ShowCarrierAccount',
  data() {
    return {
      carrierAccount: {},
      apiAccount: {}
    }
  },
  components: {
    OperationHistoryList,
    ShowChinaMobileApiAccount: () => import('@/views/carrier_accounts/china_mobile/Show'),
    ShowChinaUnicomApiAccount: () => import('@/views/carrier_accounts/china_unicom/Show'),
    ShowChinaTelecomApiAccount: () => import('@/views/carrier_accounts/china_telecom/Show')
  },
  computed: {
    carrierAccountId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findCarrierAccount(this.carrierAccountId).then((res) => {
        this.carrierAccount = res.data
        this.apiAccount = this.carrierAccount.api_account
      })
    }
  }
}
</script>

<style scoped>
</style>
